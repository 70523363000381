<template>
    <component :is="'div'">
  
      <template>
        <b-row
          class="content-header"
        >
  
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="9"
          >
            <b-row class="breadcrumbs-top">
              <b-col cols="12">
                <h2 class="float-left mb-0">
                  Імпорт водіїв
                </h2>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
  
      <b-card>
        
        <b-form
            class="p-2"
        >
          <!-- @submit.prevent="handleSubmit(onSubmit)" -->
  
            <div class="mb-1">
                <h4 class="mb-0">
                    Імпорт водіїв
                </h4>
            </div>

            <b-form-file
                placeholder="Виберіть файл або перетягніть його сюди..."
                drop-placeholder="Перетягніть файл сюди..."
                v-model="file"
            />

            <!-- Form Actions -->
            <div class="d-flex mt-2">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
            >
                Надіслати
            </b-button>
            </div>

        </b-form> 
      </b-card>     
  
    </component>
  </template>
  
  <script>
  import {
    BCard, BForm, BButton, BFormFile, BRow, BCol,
  } from 'bootstrap-vue'
  import { ref, onUnmounted } from '@vue/composition-api'
  import router from '@/router'
  import store from '@/store'
  import Ripple from 'vue-ripple-directive'
  
  export default {
    components: {
        BCard, 
        BForm, 
        BButton,
        BFormFile,
        BRow, 
        BCol,
    },
    directives: {
      Ripple,
    },
    data() {
        return {
            file: null,
        }
    },
    setup() {
        
      return {
      }
    },
  }
  </script>
  
  <style>
  
  </style>
  